<template>
    <div>
        <!-- #todo logo and name  -->
        <div class=" bg-dark p-2 mt-3 full-width" >

            <div class="row">
                <div class="col-md-1 pt-1 pr-1 col-sm-1 col-xs-12">
                    <b-img src="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png" class="w-50 ml-5"></b-img>
                </div>

                <div class="col-md-6 col-sm-4 col-xs-12">
                    <h3 class="text-white mt-3">Client Report</h3>
                </div>


            </div>
        </div>

        <div style="background-color: white; padding:2%; margin-top:1%;">
            <div class="row mb-3 mt-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mb-2 mr-sm-2">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mb-2 mr-sm-2">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>
                        <!-- to be adday any time function -->
                        <div class="form-group mb-2 mr-sm-2">
                            <select v-model="filter.period" class="form-control">
                                <option value="" selected>-- Any Time --</option>
                                <option v-for="timeperiod in period" :key="timeperiod" :value="timeperiod">{{timeperiod}}</option>
                            </select>
                        </div>
                        <div class="form-group mb-2 mr-sm-2">
                            <select v-model="filter.product" @change="productName($event)" class="form-control">
                                <option value="" selected>-- Any Product--</option>
                                <option v-for="product in products" :key="product._id" :value="product._id" >{{product.name}}</option>
                            </select>
                        </div>
                        <div class="form-group mb-2 mr-sm-2">
                            <select v-model="filter.client" class="form-control">
                                <option value="" selected>-- Any Client --</option>
                                <option v-for="client in dropdownClients" :key="client._id" :value="client._id">{{client.name}}</option>
                            </select>
                        </div>

                        <div class="form-group  mb-2 mr-sm-2">
                            <select v-model="filter.campaign_id" class="form-control">
                                <option value="" selected>-- Any Campaign --</option>
                                <option v-for="campaign in dropdownCampaigns" :key="campaign.value" :value="campaign.value">{{ campaign.text }}</option>
                            </select>
                        </div>
                        <!-- <div class="form-group mb-2 mr-sm-2">
                            <select v-model="filter.plan"  class="form-control">
                                <option value="" selected>-- Any Plan--</option>
                                <option v-for="plan in plans"  :key="plan.ProxyType" :value="plan.ProxyType">{{plan.ProxyType}}</option>
                            </select>
                        </div> -->
                        <button
                            type="button"
                            class="btn text-white mb-2 mr-sm-2"
                            @click="getDetails"
                            style="background-color: #383838"
                        >
                            <i class="fa fa-search" title="Search"></i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mb-2 mr-sm-2"
                            v-if="showExportButton"
                            @click="tableToExcel(clientName )"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else >


                <table class="table bg-white table-bordered" id="tblData" >

                    <tr>
                        <td scope="row" class="font-weight-bold">Client Name</td>
                        <td>{{clientName}}</td>
                        <td v-for="index in 4" :key="index" >&nbsp;</td>
                    </tr>
                    <tr>
                        <td scope="row" class="font-weight-bold">Date Type Requested </td>
                        <td>{{datePeriod}}</td>
                        <td v-for="index in 4" :key="index" >&nbsp;</td>
                    </tr>
                    <tr>
                        <td scope="row" class="font-weight-bold">Date Range </td>
                        <td>{{dateRange}}</td>
                        <td v-for="index in 4" :key="index" >&nbsp;</td>
                    </tr>
                    <tr>
                        <td scope="row" class="font-weight-bold">Product </td>
                        <td>{{product}}</td>
                        <td v-for="index in 4" :key="index" >&nbsp;</td>
                    </tr>
                    <tr>
                        <td v-for="index in 6" :key="index" >&nbsp;</td>
                    </tr>
                    <tbody v-for="(data, idx) in reports" :key="data.product">
                    <tr v-if="idx +1 > 1" class="table-active">
                        <td v-for="index in 5" :key="index" >&nbsp;</td>
                        <th>&nbsp;</th>
                    </tr>
                    <!-- <tr>
                        <th >Plan</th>
                        <th>{{idx + 1}}</th>
                    </tr> -->
                    <!-- <tr>
                        <th scope="row">Usage Type</th>
                        <td class="font-weight-normal"> {{data.usageType}}</td>
                        <th scope="row">Proxy Type</th>
                        <td class="font-weight-normal" > {{data.proxType}}</td>
                        <th scope="row">Size</th>
                        <td class="font-weight-normal"  > {{data.planNumberOfClicks}}</td>
                        <td v-for="index in 1" :key="index" >&nbsp;</td>
                    </tr> -->
                    <!-- <tr>
                        <th id="co3" headers="blank">Target</th>
                        <td class="font-weight-normal" headers="co3 c2" >{{data.numberOfClicks}}</td>
                        <td v-for="index in 5" :key="index" >&nbsp;</td>
                    </tr> -->

                    <tr>
                        <th id="co3" headers="blank">Campaign Dates</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.campaignDates}}</td>
                        <th id="co3" headers="blank">Campaign Times</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.campaignTimes}}</td>
                        <th id="co3" headers="blank">Campaign Status</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.status}}</td>
                        <!-- <td v-for="index in 1" :key="index" >&nbsp;</td> -->
                    </tr>

                    <tr>
                        <th id="co3" headers="blank">TOS</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.tos}}</td>
                        <th id="co3" headers="blank">Link level</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.linkLevel}}</td>
                        <th id="co3" headers="blank">% Above Fold</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.aboveFoldPercentage}}</td>
                        <!-- <td v-for="index in 1" :key="index" >&nbsp;</td> -->
                    </tr>


                    <tr>
                        <!-- <th id="co3" headers="blank">Credits Purchased</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.planNumberOfClicks}}</td> -->
                        <!-- <th id="co3" headers="blank">GA ID</th>
                        <td class="font-weight-normal" headers="co3 c2"> {{data.googleanalytics || 'No ID Found'}}</td>
                        <td v-for="index in 3" :key="index" >&nbsp;</td> -->
                    </tr>

                    <tr>
                        
                        <th id="co3" headers="blank">Target</th>
                        <td class="font-weight-normal" headers="co3 c2" >{{data.numberOfClicks}}</td>
                        <th id="co3" headers="blank">Delivered</th>
                        <td class="font-weight-normal" headers="co3 c2" >{{data.totalClicksDelivered}}</td>
                        <th id="co3" headers="blank">% Delivered</th>
                        <td class="font-weight-normal" headers="co3 c2" >{{data.totalClicksPercentage || 0 }}</td>
                        <!-- <td v-for="index in 1" :key="index" >&nbsp;</td> -->
                    </tr>
                    <tr>
                        <th id="co3" headers="blank">Geo Location</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.geos}}</td>
                        <th id="co3" headers="blank">Delivered Faild</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.totalDeliveredFail}}</td>
                        <!-- <th id="co3" headers="blank">% Above Fold</th>
                        <td class="font-weight-normal" headers="co3 c2" > {{data.aboveFoldPercentage}}</td> -->
                        <td v-for="index in 2" :key="index" >&nbsp;</td>
                    </tr>

                    <tr>
                        <!-- <th id="co3" headers="blank">% Delivered</th>
                        <td class="font-weight-normal" headers="co3 c2" >{{data.totalClicksPercentage || 0 }}</td> -->
                        <td v-for="index in 6" :key="index" >&nbsp;</td>
                    </tr>
                    <tr class="table-info">
                        <th id="co3" headers="blank">Date</th>
                        <th id="co4" headers="blank">Target</th>
                        <th id="co3" headers="blank">Requested</th>
                        <th id="co4" headers="blank">Delivered</th>
                        <th id="co3" headers="blank">% Delivered</th>
                        <th id="co4" headers="blank">Avg.TOS </th>
                        <!--                    <th id="co4" headers="blank">Avg.Bounce </th>-->
                    </tr>
                    <tr v-for="(datas) in reports[idx].newdailyBreakdown" :key="datas.data">
                        <td headers="co3 c2" >{{datas.date}}</td>
                        <td headers="co3 c2">{{datas.target}}</td>
                        <td headers="co4 c2">{{datas.attempts}}</td>
                        <td headers="co4 c2">{{datas.success}}</td>
                        <td headers="co4 c2">{{datas.successp}}</td>
                        <td headers="co4 c2">{{datas.tosAvg|toTime}}</td>
                        <!--                    <td headers="co4 c2">0</td>-->
                    </tr>
                    <tr>
                        <td headers="co3 c2">&nbsp;</td>
                        <td headers="co3 c2">Total</td>
                        <td>{{reports[idx].newdailyBreakdown|tablesumRequested}}</td>
                        <td>{{reports[idx].newdailyBreakdown|tablesumSuccess}}</td>
                        <td>{{reports[idx].newdailyBreakdown|tablesumPercentage}}</td>
                        <!-- <td headers="co4 c2">{{pagetotal(idx)}}</td> -->
                        <td>{{reports[idx].newdailyBreakdown ? (reports[idx].newdailyBreakdown|tablesumAvg): (tablesumAvg|toTime)}}</td>
                        <!-- <td headers="co4 c2">{{tablesumAvg|toTime}}</td> -->
                    </tr>

                    </tbody>
                </table>

            </div>
        </div>



    </div>
</template>

<script>
import {ExcelExport, Aggregate , Filter, GridPlugin, Group, Page, Resize, Sort} from "@syncfusion/ej2-vue-grids";
import axios from "axios";
import XLSX from "xlsx";
import { saveAs } from 'file-saver';
import Vue from "vue";
Vue.use(GridPlugin);
export default {
    name: "ClientsReport",
    data: function () {
        return {
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>{{data.Sum}}</span>`,
                        data () {return { data: {data: {}}};}
                    })
                }
            },
            footerAverage: function () {
                return  { template : Vue.component('averageTemplate', {
                        template: `<span>{{data.Average}}</span>`,
                        data () {return { data: {data: {}}};}
                    })
                }
            },
            footerT: function () {
                return  { template : Vue.component('averageTemplate', {
                        template: `<span>Total</span>`,
                        data () {return { data: {data: {}}};}
                    })
                }
            },
            filter:{
                to: '',
                from: '',
                client: '',
                product: '',
                plan: '',
                period: 'Daily',
                campaign_id: ''
            },
            clientName: '',
            dateRange:'',
            datePeriod:'',
            product: '',
            period: ['Annual','Monthly','Weekly','Daily'],
            target:'',
            item:[],
            report:[],
            reports:[],
            dropdownClients: [],
            dropdownCampaigns: [],
            clients: [],
            products: [],
            plans: [],

            tablesumAvg: 0,
            cr: 0,
            cd :0,
            av: 0,
            ab: 0,
            cp: 0,
            proxyDataset: {},
            pageSettings: {pageSize: 100},

            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            searching: false,
            showExportButton: false,
            reportLoading: false,
        }
    },
    created: function(){

        this.loadProducts();
        this.GetClientsList();
        this.loadPlans();
        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },


    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter,
            Resize,
            Aggregate
        ]
    },
    methods:{
        callit:function(){
            alert('it call')
        },
        GetClientsList: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        GetCampaignsList: function() {
            this.filter.campaign_id = '';
            let request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/client/campaign/ev/${this.filter.client}`, {params:request}).then(function(resp){
                this.dropdownCampaigns = ( resp.data ) ? resp.data.data : [];
            }.bind(this));
        },
        loadPlans:  function () {
            // alert('called')
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;
            return axios.get(`${this.$root.serverUrl}/admin/products/plansFiltered`, {params:request}).then(function(resp){
                //Store the stats
                this.plans = (resp.data)? resp.data.data : [];
            }.bind(this));


        },
        loadProducts: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.products = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        actionHandler: function(args) {
            console.log(args);
        },
        productName : function (name){
            //this is used because only the id is being passed and the name does match back in the api response
            // so it had to be done like this.
            this.products.forEach((prod)=>{
                if(prod._id == name.target.value){
                    switch(prod.name){
                        case 'Email Verifier':
                            this.filter.product_type = 'emailverifier'
                            break;
                        case 'AdVerifier':
                            this.filter.product_type = 'adverifier'
                            break;
                        case 'Content Catcher':
                            this.filter.product_type = 'contentcatcher'
                            break;
                    }
                }
            })
        },
        getDetails: async function (){
            this.reportLoading = true
            this.filter.domain_id = this.$route.query.dom_id;
            // this.filter.product_type = this.filter.product

            let result = await   axios.get(`${this.$root.serverUrl}/admin/reports/client`, {params:this.filter})
            this.item = ( result && result.data  ) ? result.data : [];
            this.report = ( result && result.data && result.data.data ) ? result.data.data : [];
            this.clientName = result.data.clientName;
            this.dateRange  = result.data.dateRange;
            this.numberOfClicks  = result.data.numberOfClicks;
            this.datePeriod  = result.data.datePeriod;
            this.product = result.data.product;
            // console.log( this.report)
            this.target = this.report ? this.report.target : ''
            if(result){
                this.reportLoading = false
            }
            if(this.report) this.showExportButton = true;
            // console.log(this.report )


            this.reports = [...this.report]

            for(let x = 0; x < this.report.length; x++){
                this.reports[x].newdailyBreakdown = []
                this.reports[x].total = []
                for(let i in this.report[x].dailyBreakdown){

                    console.log(this.report[x].dailyBreakdown[i])
                    let sucessP =  this.report[x].dailyBreakdown[i].delivered / this.report[x].dailyBreakdown[i].requested * 100 | 0
                    this.reports[x].newdailyBreakdown.push({
                        date:this.report[x].dailyBreakdown[i].days,
                        target:this.report[x].dailyBreakdown[i].target,
                        attempts:this.report[x].dailyBreakdown[i].requested,
                        
                        success:this.report[x].dailyBreakdown[i].delivered,
                        fail:this.report[x].dailyBreakdown[i].requested - this.report[x].dailyBreakdown[i].delivered,
                        successp:sucessP.toFixed(0),
                        clickReq:this.report[x].dailyBreakdown[i].clicksRequested,
                        tosAvg:this.report[x].dailyBreakdown[i].tosAvg,
                        tosTotal: this.report[x].dailyBreakdown[i].tosTotal
                    })
                    this.tablesumAvg +=this.report[x].dailyBreakdown[i].tosAvg;
                }
            }
            console.log(this.reports)


        },


        priceFormatter(data) {
            var field = this.field
            return '$' + data.map(function (row) {
                return +row[field].substring(1)
            }).reduce(function (sum, i) {
                return sum + i
            }, 0)
        },
        tableToExcel(name){
            var wb = XLSX.utils.table_to_book(document.getElementById('tblData'), {sheet:"Sheet JS"});
            var wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'});
            saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), name+'_campiagns_report.xlsx');
        },
        s2ab:function(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        },

    },
    watch:{
        "filter.client": function(){
            if(this.filter.client.length > 0){
                this.GetCampaignsList();
            }else{
                this.dropdownCampaigns = [];
                this.filter.campaign_id = "";
            }
        }
    }
}

</script>


<style scoped>

</style>
